import {CreateControllerFn} from '@wix/yoshi-flow-editor'

import {isTemplateView} from '../../utils/flowAPI'

import {initReservationDetailsStorage, mockReservationDetailsStorage} from './storage'

const createController: CreateControllerFn = async ({flowAPI}) => {
  return {
    async pageReady() {
      const isEditor = flowAPI.environment.isEditor
      const isPreview = flowAPI.environment.isPreview
      const isTemplate = isTemplateView(flowAPI)

      if (isEditor || isPreview || isTemplate) {
        const mockedStorage = await mockReservationDetailsStorage(flowAPI)
        flowAPI.controllerConfig.setProps(mockedStorage)
      } else {
        const storage = initReservationDetailsStorage(flowAPI)
        flowAPI.controllerConfig.setProps(storage)

        if (storage.isReservationIdQueryParamValid) {
          await Promise.all([
            storage.getReservation(storage.reservationIdQueryParam!),
            storage.getReservationLocations(),
          ])
        } else if (storage.isReservationDataQueryParamsValid) {
          await storage.getReservationLocations()
        } else {
          storage.goToNewReservation()
        }
      }
    },
  }
}

export default createController
