import {ControllerFlowAPI} from '@wix/yoshi-flow-editor'
import {Reservee, Details} from '@wix/ambassador-table-reservations-v1-reservation/types'
import {ReservationLocation} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {reservationsService} from '../../../services/reservationsService'
import {RequestStatus} from '../../../utils/wrapRequest'
import {goToReservationConfirmation} from '../../../utils/navigation'
import {getLogger} from '../../../utils/getLogger'

export const createReservation = (flowAPI: ControllerFlowAPI) => {
  return async (details: Details, reservee: Reservee, reservationLocation: ReservationLocation) => {
    const setProps = flowAPI.controllerConfig.setProps
    const logger = getLogger(flowAPI.bi!)

    try {
      setProps({submitReservationStatus: RequestStatus.LOADING})
      const finishedReservation = await reservationsService.createReservation(flowAPI, {
        details,
        reservee,
      })

      // this is the only place where can get just created reservation
      if (finishedReservation?.id) {
        logger.reservationCreated({
          isPreview: false,
          reservation: finishedReservation,
          reservee,
          reservationLocation,
        })

        await goToReservationConfirmation(flowAPI, finishedReservation.id)
      } else {
        throw new Error('No reservation')
      }
    } catch (err: any) {
      setProps({
        submitReservationStatus: RequestStatus.FAILED,
        apiRequestErrorDetails: err,
      })
    }
  }
}
