import isValid from 'date-fns/isValid'
import isNaN from 'lodash/isNaN'
import {validate as validateUuid} from 'uuid'

interface ReservationDataQuery {
  startDate?: string
  partySize?: string
  reservationLocationId?: string
}

export const getReservationDataQueryParamsValidation = (
  {startDate, partySize, reservationLocationId}: ReservationDataQuery,
  allowEmpty = false,
) => {
  const isStartDateValid = startDate ? isValid(new Date(startDate)) : allowEmpty
  const isPartySizeValid = partySize ? !isNaN(partySize) : allowEmpty
  const isReservationLocationIdValid = reservationLocationId
    ? validateUuid(reservationLocationId)
    : allowEmpty

  return {
    startDate: isStartDateValid,
    partySize: isPartySizeValid,
    reservationLocationId: isReservationLocationIdValid,
  }
}
export const validateReservationDataQueryParam = (query: ReservationDataQuery) => {
  const validationResult = getReservationDataQueryParamsValidation(query)

  return (
    validationResult.startDate &&
    validationResult.partySize &&
    validationResult.reservationLocationId
  )
}

export const validateReservationIdQueryParam = ({reservationId = ''}: {reservationId?: string}) =>
  validateUuid(reservationId)
